import { AppBar, Container, Toolbar, Button, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
// import Hero from "../sections/Hero";
import "../sections/css/header.css";
import down1 from "../img/ic_dropdown _white.svg"
import down from "../img/ic_dropdown.svg"
import blog from "../img/ic_blogs_new.svg"
// import link from "../img/ic_visit.svg"
import HamburgerMenu from "./HamburgerMenu";
import logo from "../img/ic_zaperon_logo.svg"
import logoWhite from "../img/ic_zaperon_logo_white.svg"
import docs from "../img/ic_help_docs.svg"
import prodImg from "../img/img_product.webp"
import ucImg from "../img/img_usecases.webp"
import passwordless from "../img/ic_passwordless.svg"
import cdp from "../img/ic_cdp.svg"
import policies from "../img/ic_policies.svg"
import sso from "../img/ic_sso.svg"
import mfa from "../img/ic_mfa.svg"
import deviceTrust from "../img/ic_device_trust.svg"
import linkIcon from "../img/ic_visit.svg"

import phish from "../img/ic_phishing.svg"
import sma from "../img/ic_secure_remote_access.svg"
import dlp from "../img/ic_data_loss_prevention.svg"
import threat from "../img/ic_threat.svg"
import ssa from "../img/ic_secure_server_access.svg"
import monitor from "../img/ic_monitor.svg"

const pages = ["Product", "Use Cases", "Resources", "About"];

function Header(props) {
  const [colors, setColors] = useState(["#FFFFFF", "#003FB9", "#0B3558", "0px 3px 6px #0000001F"]);
  const [showDrpdwn, setShowDrpdwn] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [currPath, setCurrPath] = useState();
  useEffect(() => {
    const path = location.pathname;
    const part = path.split('blog/')[1]
    if (part) {
      setCurrPath("/blog");
      return;
    }
    setCurrPath(path);
    // console.log(part)
  }, [location])
  const scrollFunction = (id) => {
    try {
      console.log(id);
      document.getElementById(id).scrollIntoView(true);
      window.scrollTo(
        0,
        document.getElementById(id).getBoundingClientRect().top -
        70 +
        window.scrollY
      );
      window.scrollTo({
        top:
          document.getElementById(id).getBoundingClientRect().top -
          30 +
          window.scrollY,
        left: 0,
        behavior: "smooth",
      });
    } catch (e) { }
  };

  const handleHover = (event) => {
    const parentElement = event.target;
    const image = parentElement.querySelector('.rotate-image');
    if (image === null) {
      return;
    }
    // console.log(image)
    image.style.transformOrigin = 'center';
    image.style.transform = 'rotateX(-180deg)';


  };
  const handleLeave = (event) => {
    const parentElement = event.target;
    const image = parentElement.querySelector('.rotate-image');

    if (image) {
      image.style.transformOrigin = 'initial';
      image.style.transform = 'rotateX(0deg)';
    }
  };

  const handleLinkoff = (event) => {
    const parentElement = event.target;
    const image = parentElement.querySelector('.navlink');
    if (image) {
      image.style.display = 'none';
    }
  };

  const handleLinkon = (event) => {
    const parentElement = event.target;
    const image = parentElement.querySelector('.navlink');
    if (image) {
      image.style.display = 'inline-flex';
    }
  };


  // const changeNavbarColor = () => {
  //   if (window.scrollY >= 0) {
  //     setColors(["#FFFFFF", "#003FB9", "#0B3558", "0px 3px 6px #0000001F"]);
  //   } else {
  //     setColors([
  //       "transparent",
  //       "#FFFFFF",
  //       "#FFFFFF",
  //       "none"
  //     ]);
  //     // setColors(["#FFFFFF", "#003FB9", "#0B3558", "0px 3px 6px #0000001F"]);
  //   }
  // };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setColors(["#FFFFFF", "#003FB9", "#0B3558", "0px 3px 6px #0000001F"]);
      } else {
        setColors(["#FFFFFF", "#003FB9", "#0B3558", "0px 3px 6px #0000001F"]);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  const headerLinkHandler = (page) => {
    setShowDrpdwn(false);
    setTimeout(() => {
      setShowDrpdwn(true);
    }, [500])
    navigate(page);
  }

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (props.scrollY === 0) {
  //       setColors([
  //         "transparent",
  //         "#FFFFFF",
  //         "#FFFFFF",
  //         "none"
  //       ])
  //     } else {
  //       setColors(["#FFFFFF", "#003FB9", "#0B3558", "0px 3px 6px #0000001F"]);
  //     }
  //   }
  //   handleScroll()
  // }, [props.scrollY])

  // if(props.flag==="contact"){
  //   setColors(["#FFFFFF", "#003FB9", "#0B3558", "0px 3px 6px #0000001F"]);
  // }
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: colors[0],
        m: "auto",
        position: "fixed",
        top: 0,
        zIndex: "50",
        boxShadow: colors[3],
      }}
    >
      <Container disableGutters>
        <Toolbar
          sx={{
            height: { lg: "80px", md: "80px", sm: "80px", xs: "48px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Link to="/">
            {/* <div href="/" className="headerLink"> */}
            <Box
              direction={"column"}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                component={"img"}
                src={colors[0] === 'transparent' ? logoWhite : logo}
                alt={"Z"}
              // sx={{ marginLeft:{lg:"89px",md:"10px"}, marginRight: "2px" }}
              />
            </Box>
            {/* </div> */}
          </Link>
          {(currPath === "/blog" || currPath === "/blog/") &&
            <span style={{ font: "normal normal bold 24px/24px Poppins", color: "#0B3558", borderLeft: "3px solid #0B3558", marginLeft: "14px", paddingLeft: "12px", height: "33px", display: "flex", alignItems: "center" }}>
              Blogs
            </span>
          }
          <Box component={"span"} sx={{ flexGrow: { sm: 1, xs: 0 } }} />

          {pages.map((Pages, idx) => (
            Pages === "Product" ?
              <Box key={idx}
                className="dropdown"
                onMouseEnter={(e) => {
                  handleHover(e)
                }}
                onMouseLeave={(e) => {
                  handleLeave(e)
                }}
                style={{ height: "50px", paddingBottom: "64px", paddingTop: "16px" }}
              >
                <Button
                  sx={{
                    mx: { md: "10px", sm: "10px" },
                    p: "10px",
                    display: { md: "block", sm: "none", xs: "none" },
                    font: "normal normal 600 16px/25px Poppins,sans-serif",
                    letterSpacing: 0,
                    color: colors[2],
                    textTransform: "none",
                    ":hover": {
                      color: "#003FB9",
                      background: "#EEF3FF",
                      font: "normal normal 600 16px/25px Poppins"
                    },
                  }}
                >
                  <span className="hideme">{Pages}{colors[2] === "#0B3558" ? <img className="rotate-image" src={down} alt="" style={{ paddingLeft: "5px", maxWidth: "30px" }} /> : <img className="rotate-image" src={down1} alt="" style={{ paddingLeft: "5px", maxWidth: "30px" }} />}</span>

                </Button>
                {showDrpdwn && <Box className="dropdown-content" style={{ width: "1269px", height: "688px", position: "absolute", left: "-380px" }}>
                  <div style={{ display: "flex", justifyContent: "start", marginBottom: "auto", marginTop: "32px" }}>
                    <div style={{ marginRight: "100px", width: "640px"}}>
                      <img alt="pi" src={prodImg} style={{ width: "640px" }} />
                      <div style={{ font: "normal normal bold 24px/35px Poppins", color: "#0B3558", marginTop: "24px" }}>A Unified Platform to Secure Modern Workplaces</div>
                      <div style={{ font: " normal normal normal 16px/25px Poppins", color: "#6E6F70" }}>Apply zero trust principles to secure your users, apps and devices. Reduce attack surface by 80% with phishing-resistant MFA and passwordless authentication.</div>
                    </div>
                    <div style={{ width: "400px" }}>
                      <div style={{ font: "normal normal bold 18px/27px Poppins", color: "#0B3558", borderBottom: "1px solid #CDD3E0", paddingBottom: "13px" }}>Key capabilties</div>
                      <div style={{ marginTop: "23px" }}>
                        <Box className="linkRow" style={{ display: "flex", alignItems: "center", marginTop: "16px", height: "64px" }}
                          sx={{
                            ":hover": {
                              background: "#EEF3FF",
                              borderRadius: "4px"
                            }
                          }}
                        >
                          <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img alt="psd" src={passwordless} />
                          </div>
                          <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Passwordless Access</div>
                          <div className="linkIcon" style={{ marginLeft: "auto" }}>
                            <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                          </div>
                        </Box>

                        <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px" }}
                          sx={{
                            ":hover": {
                              background: "#EEF3FF",
                              borderRadius: "4px"
                            }
                          }}
                        >
                          <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img alt="mf" src={mfa} />
                          </div>
                          <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Multi-factor Authentication (MFA)</div>
                          <div className="linkIcon" style={{ marginLeft: "auto" }}>
                            <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                          </div>
                        </Box>

                        <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px" }}
                          sx={{
                            ":hover": {
                              background: "#EEF3FF",
                              borderRadius: "4px"
                            }
                          }}
                        >
                          <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img alt="ss" src={sso} />
                          </div>
                          <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Single Sign On (SSO)</div>
                          <div className="linkIcon" style={{ marginLeft: "auto" }}>
                            <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                          </div>
                        </Box>

                        <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px" }}
                          sx={{
                            ":hover": {
                              background: "#EEF3FF",
                              borderRadius: "4px"
                            }
                          }}
                        >
                          <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img alt="pol" src={policies} />
                          </div>
                          <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Zero Trust Access Policies</div>
                          <div className="linkIcon" style={{ marginLeft: "auto" }}>
                            <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                          </div>
                        </Box>

                        <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px" }}
                          sx={{
                            ":hover": {
                              background: "#EEF3FF",
                              borderRadius: "4px"
                            }
                          }}
                        >
                          <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img alt="cd" src={cdp} />
                          </div>
                          <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Cloud Data Protection</div>
                          <div className="linkIcon" style={{ marginLeft: "auto" }}>
                            <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                          </div>
                        </Box>

                        <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px", marginBottom: "40px" }}
                          sx={{
                            ":hover": {
                              background: "#EEF3FF",
                              borderRadius: "4px"
                            }
                          }}>

                          <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img alt="devtr" src={deviceTrust} />
                          </div>
                          <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Continuous Device Trust</div>
                          <div className="linkIcon" style={{ marginLeft: "auto" }}>
                            <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                          </div>
                        </Box>
                        <button onClick={() => headerLinkHandler('/keyCapabilities')} style={{ background: "none", cursor: "pointer", width: "206px", border:"none" }}>
                          <div style={{ marginLeft: "16px", width: "206px", height: "47px", border: "2px solid #003FB9", borderRadius: "4px", font: "normal normal 600 16px/25px Poppins", color: "#003FB9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            Explore all Features
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </Box>}
              </Box>
              : Pages === "Use Cases" ?
                <Box key={idx}
                  className="dropdown"
                  onMouseEnter={(e) => {
                    handleHover(e)
                  }}
                  onMouseLeave={(e) => {
                    handleLeave(e)
                  }}
                  style={{ height: "50px", paddingBottom: "64px", paddingTop: "16px" }}
                >
                  <Button
                    sx={{
                      mx: { md: "10px", sm: "10px" },
                      p: "10px",
                      display: { md: "block", sm: "none", xs: "none" },
                      font: "normal normal 600 16px/25px Poppins,sans-serif",
                      letterSpacing: 0,
                      color: colors[2],
                      textTransform: "none",
                      ":hover": {
                        color: "#003FB9",
                        background: "#EEF3FF",
                        font: "normal normal 600 16px/25px Poppins"
                      },
                    }}
                  >
                    <span className="hideme">{Pages}{colors[2] === "#0B3558" ? <img className="rotate-image" src={down} alt="" style={{ paddingLeft: "5px", maxWidth: "30px" }} /> : <img className="rotate-image" src={down1} alt="" style={{ paddingLeft: "5px", maxWidth: "30px" }} />}</span>

                  </Button>
                  {showDrpdwn && <Box className="dropdown-content" style={{ width: "1269px", height: "688px", position: "absolute", left: "-500px" }}>
                    <div style={{ display: "flex", justifyContent: "start", marginBottom: "auto", marginTop: "32px" }}>
                      <div style={{ marginRight: "100px", width: "250px", textAlign: "left" }}>
                        <img alt="pi" src={ucImg} style={{ width: "250px" }} />
                        <div style={{ font: "normal normal bold 18px/27px Poppins", color: "#0B3558", marginTop: "24px" }}>Secure Remote Access for your workforce from any device, anywhere</div>
                        <div style={{ font: " normal normal normal 12px/18px Poppins", color: "#6E6F70", marginTop: "8px" }}>Continuously validate users, apps and devices with passwordless authentication, access policies and phishing-resistant MFA.</div>
                      </div>

                      <div style={{ width: "400px" }}>
                        <div style={{ font: "normal normal bold 18px/27px Poppins", color: "#0B3558", borderBottom: "1px solid #CDD3E0", paddingBottom: "13px", width: "350px" }}>Industries</div>
                        <div style={{ marginTop: "23px" }}>
                          <Box className="linkRow" style={{ display: "flex", alignItems: "center", marginTop: "16px", height: "64px" }}
                            sx={{
                              ":hover": {
                                background: "#EEF3FF",
                                borderRadius: "4px"
                              }
                            }}
                          >
                            <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <img alt="psd" src={passwordless} />
                            </div>
                            <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Banking & Financial Services</div>
                            <div className="linkIcon" style={{ marginLeft: "auto" }}>
                              <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                            </div>
                          </Box>

                          <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px" }}
                            sx={{
                              ":hover": {
                                background: "#EEF3FF",
                                borderRadius: "4px"
                              }
                            }}
                          >
                            <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <img alt="mf" src={mfa} />
                            </div>
                            <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Manufacturing</div>
                            <div className="linkIcon" style={{ marginLeft: "auto" }}>
                              <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                            </div>
                          </Box>

                          <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px" }}
                            sx={{
                              ":hover": {
                                background: "#EEF3FF",
                                borderRadius: "4px"
                              }
                            }}
                          >
                            <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <img alt="ss" src={sso} />
                            </div>
                            <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Technology</div>
                            <div className="linkIcon" style={{ marginLeft: "auto" }}>
                              <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                            </div>
                          </Box>

                          <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px" }}
                            sx={{
                              ":hover": {
                                background: "#EEF3FF",
                                borderRadius: "4px"
                              }
                            }}
                          >
                            <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <img alt="pol" src={policies} />
                            </div>
                            <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Healthcare</div>
                            <div className="linkIcon" style={{ marginLeft: "auto" }}>
                              <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                            </div>
                          </Box>

                          <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px" }}
                            sx={{
                              ":hover": {
                                background: "#EEF3FF",
                                borderRadius: "4px"
                              }
                            }}
                          >
                            <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <img alt="cd" src={cdp} />
                            </div>
                            <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Retail</div>
                            <div className="linkIcon" style={{ marginLeft: "auto" }}>
                              <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                            </div>
                          </Box>

                          <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px", marginBottom: "40px" }}
                            sx={{
                              ":hover": {
                                background: "#EEF3FF",
                                borderRadius: "4px"
                              }
                            }}>

                            <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <img alt="devtr" src={deviceTrust} />
                            </div>
                            <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Government</div>
                            <div className="linkIcon" style={{ marginLeft: "auto" }}>
                              <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                            </div>
                          </Box>
                        </div>
                      </div>

                      <div style={{ width: "400px" }}>
                        <div style={{ font: "normal normal bold 18px/27px Poppins", color: "#0B3558", borderBottom: "1px solid #CDD3E0", paddingBottom: "13px", width: "350px" }}>Use Cases</div>
                        <div style={{ marginTop: "23px" }}>
                          <Box className="linkRow" style={{ display: "flex", alignItems: "center", marginTop: "16px", height: "64px" }}
                            sx={{
                              ":hover": {
                                background: "#EEF3FF",
                                borderRadius: "4px"
                              }
                            }}
                          >
                            <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <img alt="psd" src={phish} />
                            </div>
                            <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Phishing Prevention</div>
                            <div className="linkIcon" style={{ marginLeft: "auto" }}>
                              <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                            </div>
                          </Box>

                          <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px" }}
                            sx={{
                              ":hover": {
                                background: "#EEF3FF",
                                borderRadius: "4px"
                              }
                            }}
                          >
                            <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <img alt="mf" src={sma} />
                            </div>
                            <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Secure Remote Access</div>
                            <div className="linkIcon" style={{ marginLeft: "auto" }}>
                              <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                            </div>
                          </Box>

                          <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px" }}
                            sx={{
                              ":hover": {
                                background: "#EEF3FF",
                                borderRadius: "4px"
                              }
                            }}
                          >
                            <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <img alt="ss" src={dlp} />
                            </div>
                            <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Data Loss Prevention</div>
                            <div className="linkIcon" style={{ marginLeft: "auto" }}>
                              <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                            </div>
                          </Box>

                          <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px" }}
                            sx={{
                              ":hover": {
                                background: "#EEF3FF",
                                borderRadius: "4px"
                              }
                            }}
                          >
                            <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <img alt="pol" src={threat} />
                            </div>
                            <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Insider Threat Mitigation</div>
                            <div className="linkIcon" style={{ marginLeft: "auto" }}>
                              <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                            </div>
                          </Box>

                          <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px" }}
                            sx={{
                              ":hover": {
                                background: "#EEF3FF",
                                borderRadius: "4px"
                              }
                            }}
                          >
                            <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <img alt="cd" src={ssa} />
                            </div>
                            <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Secure Server Access</div>
                            <div className="linkIcon" style={{ marginLeft: "auto" }}>
                              <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                            </div>
                          </Box>

                          <Box className="linkRow" style={{ display: "flex", alignItems: "center", height: "64px", marginBottom: "40px" }}
                            sx={{
                              ":hover": {
                                background: "#EEF3FF",
                                borderRadius: "4px"
                              }
                            }}>

                            <div style={{ marginLeft: "16px", height: "48px", width: "48px", background: "#CEDCFF", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <img alt="devtr" src={monitor} />
                            </div>
                            <div style={{ font: "normal normal 600 14px/21px Poppins", color: "#0B3558", marginLeft: "15px" }}>Regulatory Compliance</div>
                            <div className="linkIcon" style={{ marginLeft: "auto" }}>
                              <img src={linkIcon} alt="link ic" style={{ marginRight: "12px", width: "16px" }} />
                            </div>
                          </Box>
                          <button onClick={() => headerLinkHandler('/useCases')} style={{ background: "none", cursor: "pointer", maxWidth: "206px", border:"none" }}>
                            <div style={{ marginLeft: "16px", width: "206px", height: "47px", border: "2px solid #003FB9", borderRadius: "4px", font: "normal normal 600 16px/25px Poppins", color: "#003FB9", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              Explore all Usecases
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Box>}
                </Box>
                : Pages === "Resources" ?
                  <Box key={idx} className="dropdown" onMouseEnter={handleHover}
                    onMouseLeave={handleLeave}>
                    <Button
                      sx={{
                        mx: { md: "10px", sm: "10px" },
                        p: "10px",
                        display: { md: "block", sm: "none", xs: "none" },
                        font: "normal normal 600 16px/25px Poppins,sans-serif",
                        letterSpacing: 0,
                        color: colors[2],
                        textTransform: "none",
                        ":hover": {
                          color: "#003FB9",
                          background: "#EEF3FF",
                          font: "normal normal 600 16px/25px Poppins"
                        },
                      }}
                    >
                      <span className="hideme">{Pages}{colors[2] === "#0B3558" ? <img className="rotate-image" src={down} alt="" style={{ paddingLeft: "5px", maxWidth: "30px" }} /> : <img className="rotate-image" src={down1} alt="" style={{ paddingLeft: "5px", maxWidth: "30px" }} />}</span>

                    </Button>
                    <Box className="dropdown-content">
                      {/* <div className="triangle-up"></div> */}
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>

                      <Link to="/blog" style={{ marginTop: "30px" }}>
                        <Box
                          onMouseEnter={handleLinkon}
                          onMouseLeave={handleLinkoff}
                          sx={{
                            display: { md: "flex", sm: "flex", xs: "none" },
                            alignItems: "center",
                            font: "normal normal 600 16px/25px Poppins,sans-serif",
                            letterSpacing: 0,
                            textTransform: "none",
                            color: "#3659A7",
                            background: "none",
                            ":hover": {
                              color: "#3659A7",
                              background: "none",
                            },
                          }}
                        >
                          <img
                            src={blog}
                            style={{
                              display: "inline-flex",
                              padding: "4px",
                            }}
                            alt="Blog"
                          />
                          Blog
                        </Box>
                        </Link>

                        <a
                          href="https://docs.zaperon.com/"
                          target="_blank"
                          rel="noreferrer"
                          style={{ position: "relative", bottom: "22px" }}
                        >
                          <Box
                            onMouseEnter={handleLinkon}
                            onMouseLeave={handleLinkoff}
                            sx={{
                              display: { md: "flex", sm: "flex", xs: "none" },
                              alignItems: "center",
                              font: "normal normal 600 16px/25px Poppins,sans-serif",
                              letterSpacing: 0,
                              textTransform: "none",
                              color: "#3659A7",
                              background: "none",
                              ":hover": {
                                color: "#3659A7",
                                background: "none",
                              },
                            }}
                          >
                            <img
                              src={docs}
                              style={{
                                display: "inline-flex",
                                padding: "4px",
                              }}
                              alt="Documentation"
                            />
                            Documentation
                          </Box>
                        </a>

                      </div>
                    </Box>

                  </Box> :
                  <Button
                    key={Pages}
                    onClick={() => scrollFunction(Pages)}
                    sx={{
                      mx: { md: "10px", sm: "10px" },
                      p: "10px",
                      display: { md: "block", sm: "none", xs: "none" },
                      font: "normal normal 600 16px/25px Poppins,sans-serif",
                      letterSpacing: 0,
                      color: colors[2],
                      textTransform: "none",
                      ":hover": {
                        color: "#003FB9",
                        background: "#EEF3FF",
                        font: "normal normal 600 16px/25px Poppins"
                      },
                    }}
                  >
                    {Pages}
                  </Button>
          ))}
          <Button
            sx={{ marginRight: { sm: "-30px", xs: "-80px" }, paddin: "0px", display: { md: "none", sm: "block", xs: "block" } }}>
            <HamburgerMenu color={colors[1]} bg={colors[0]} scroll={scrollFunction} />
          </Button>
          <NavLink to='/partners' style={{ textDecoration: "none" }}>
            <Button sx={{
              mx: { md: "20px", sm: "10px" },
              p: "10px",
              display: { md: "block", sm: "none", xs: "none" },
              font: "normal normal 600 16px/25px Poppins,sans-serif",
              letterSpacing: 0,
              color: colors[2],
              textTransform: "none",
              ":hover": {
                color: "#3659A7",
                background: "none",
              },
            }}
            >
              Partners
            </Button>
          </NavLink>

          <Link to="/contact">
            <Button
              key={"Request a Demo"}
              sx={{
                px: "24px",
                py: "12px",
                // mr: {lg:'89px',md:"20px"},
                backgroundColor: "#003FB9",
                boxShadow: "0px 3px 6px #003FB929",
                borderRadius: "4px",
                opacity: "1",
                color: "#FFFFFF",
                display: { md: "block", sm: "none", xs: "none" },
                textTransform: "none",
                font: "normal normal 600 16px/25px Poppins",
                ":hover": {
                  background: "#3659A7 0% 0% no-repeat padding-box",
                  boxShadow: "0px 3px 6px #003FB929",
                  borderRadius: "4px",
                },
                // mr:"10"
              }}
            >
              Request a Demo
            </Button>
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
