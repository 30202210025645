/* eslint-disable */

import React, { useState, useEffect } from 'react';
import './css/Carousel.css';
import down from "../img/ic_chevron_left.svg"
import up from "../img/ic_chevron_right.svg"
import left from "../img/ic_testimonials.svg"
import right from "../img/ic_blob.svg"
import { Box, Button, Stack, Typography } from '@mui/material';
import quoteIcon from "../img/quote-icon.svg"
import testimonialImg1 from "../img/webp/08-Testimonial/img-testimonial-amarya_-technologies.webp"
import testimonialImg2 from "../img/webp/08-Testimonial/img-testimonial-new allenberry-works.webp"
import testimonialImg3 from "../img/webp/08-Testimonial/img-testimonial-wealth-management-firm.webp"

import {ReactComponent as ArrowSvg} from "../img/arrow_left.svg"
const Carousel = ({ reviews,reviewer,Agencies }) => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const goToPreviousReview = () => {
    setCurrentReviewIndex((prevIndex) => (prevIndex === 0 ? reviews.length - 1 : prevIndex - 1));
  };

  const goToNextReview = () => {
    setCurrentReviewIndex((prevIndex) => (prevIndex === reviews.length - 1 ? 0 : prevIndex + 1));
  };
// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const interval = setInterval(goToNextReview, 6000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  

  return (
    
<Box className='carouselBg' sx={{paddingBottom:{sm:'80px', xs: "64px"}, overflow:"hidden",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center", paddingLeft: {lg: "40px", sm: "32px", xs: "16px"}}}>
<Typography
          variant="h2"
          sx={{
            p: {
              lg: "80px 0px 80px 0px",
              sm: "80px 32px 80px 0",
              xs: "64px 16px 48px 0",

            },
            font: {
              lg: "normal normal 600 48px/120% Poppins",
              md: "normal normal 600 48px/120% Poppins",
              sm: "normal normal 600 48px/120% Poppins",
              xs: "normal normal 600 32px/120% Poppins",
            },
            width: '100%',
            color: "#0B3558",
            textAlign: "center"
          }}
        >
        Discover What Our Customers Say
        </Typography>
    <div className="carousel">
    <Box
      sx={{
        position: 'relative',
        borderRadius: "12px",
        marginRight: {lg: "48px", xs: "auto"},
        width: {lg: "632px", sm: "90%", xs: "328px"},
        height: {lg: "330px", xs: "fit-content"}
      }}
    >
      <Box
        component="img"
        src={
          currentReviewIndex === 0
            ? testimonialImg2
            : currentReviewIndex === 1
            ? testimonialImg1
            : testimonialImg3
        }
        alt='quote'
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "12px",
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: "12px",
          background: "linear-gradient(180deg, #0B3558 0.01%, rgba(11, 53, 88, 0.20) 48.64%, rgba(255, 255, 255, 0.40) 98.14%)",
        }}
      />
    </Box>

    <Box sx={{width: "520px", display: 'flex', flexDirection: "column", justifyContent: "space-between", minHeight: "100%", marginTop: {lg: 0, xs: "32px"}}}>
    <Box component="img" src={quoteIcon} alt='quote' width="64px" marginBottom={{lg: 0, xs: "32px"}} />
    <Typography color={'secondary'} variant='p' sx={{fontSize: {sm: "20px", xs: "16px"}, height: {lg: "150px", xs: "150px"}, marginBottom: {lg: 0, xs: "32px"}, width:{lg: "fit-content", sm: "704px", xs: "328px"}, fontWeight: "400", lineHeight: "120%"}}>{reviews[currentReviewIndex]}</Typography>    
    <Stack flexDirection={'column'}>
    <Typography color={'secondary'} variant='p' sx={{fontSize: "20px", fontWeight: "600", lineHeight: "120%"}}>{reviewer[currentReviewIndex]}</Typography>    
    <Typography color={'secondary'} variant='p' sx={{fontSize: "16px", fontWeight: "400", lineHeight: "120%"}}>{Agencies[currentReviewIndex]}</Typography>    

    </Stack>
      </Box>
    </div>
    <Stack flexDirection={{lg: 'row',xs: 'row'}} columnGap={{xs: '40px', sm: 0}} alignItems={'center'} justifyContent={'space-between'} marginRight={{lg: "0px", xs: "auto"}} marginLeft={{lg: 0, xs: "0"}} flex={1} width={{lg: '1200px', xs: '96%'}}>
    <Button variant='contained' onClick={goToPreviousReview} sx={{background: "transparent", border :'none', boxShadow: 'none' , padding: "0", display: {lg: "none", xs: 'block'}, borderRadius: "4px", cursor: 'pointer',":hover": {background: "transparent", border :'none', boxShadow: 'none'}}}>
    <Box sx={{padding: "16px",  maxWidth: "48px", maxHeight: "48px",background: "rgba(0, 63, 185, 1)", borderRadius: "4px" }}>
    <ArrowSvg fill='#FFF'/>
    </Box>
    </Button>
    <Stack flexDirection={'row'} width={{lg: '632px', xs: "fit-content"}} marginRight={{lg: '48px', xs: 0}} padding={0} justifyContent={'center'}>
      <Box component={'div'} sx={{background: currentReviewIndex === 0 ? "rgba(0, 63, 185, 1)": "rgba(206, 220, 255, 1)", width: "32px", height: "8px"}} ></Box>
      <Box component={'div'} sx={{background: currentReviewIndex === 1 ? "rgba(0, 63, 185, 1)": "rgba(206, 220, 255, 1)", width: "32px", height: "8px", marginLeft: "8px",marginRight: "8px"}} ></Box>
      <Box component={'div'} sx={{background: currentReviewIndex === 2 ? "rgba(0, 63, 185, 1)": "rgba(206, 220, 255, 1)", width: "32px", height: "8px"}} ></Box>
    </Stack>
  <Button variant="contained" onClick={goToNextReview} sx={{background: "transparent" , padding: "0 0", borderRadius: "4px",  display: {lg: "none", xs: 'block'},  cursor: 'pointer', transform: 'rotate(180deg)', border :'none', boxShadow: 'none', marginLeft: "0px",":hover": {background: "transparent", border :'none', boxShadow: 'none'}}}>
  <Box sx={{padding: "16px", maxWidth: "48px", maxHeight: "48px",background: "rgba(0, 63, 185, 1)", borderRadius: "4px" }}>

<ArrowSvg fill='#FFF'/>
    </Box>
  </Button>
    <Stack flexDirection={'row'} display={{xs: 'none', lg: "flex"}} sx={{transform: "translateX(-10px)"}} marginRight={"auto"} width={'fit-content'} >
    <Button variant='contained' onClick={goToPreviousReview} sx={{display: 'flex', padding: "0 0", border: "none", boxShadow: "none", background: 'transparent', width: "48px", borderRadius: "4px", cursor: 'pointer',":hover": {background: "transparent", border :'none', boxShadow: 'none'}}}>
    <Box sx={{padding: "16px",background: "rgba(0, 63, 185, 1)",  maxHeight: "48px", borderRadius: "4px" }}>

<ArrowSvg fill='#FFF'/>
    </Box>
    </Button>
    <Button variant="contained" onClick={goToNextReview} sx={{display: 'flex',background: "transparent", boxShadow: "none", border: "none" , padding: "0 0", width: "48px", borderRadius: "4px", cursor: 'pointer', transform: 'rotate(180deg) translateX(15px)', marginLeft: "12px", ":hover": {background: "transparent", border :'none', boxShadow: 'none'}}}>
    <Box sx={{padding: "16px",background: "rgba(0, 63, 185, 1)",  maxHeight: "48px", borderRadius: "4px" }}>

<ArrowSvg fill='#FFF'/>
    </Box>
    </Button>
</Stack>
    </Stack>
   
    </Box>
  );
};

export default Carousel;
