import React, { useEffect, useState } from "react";
import Footer from "../sections/Footer";
// import Header from "../ui/Header2";
import { Box, Modal, Typography } from "@mui/material";

import AppVerifyLogo from "../img/ic_zaperon_logo_verified.png";
import winLogo from "../img/ic_windows.png";
import appleLogo from "../img/ic_mac.png";
import debLogo from "../img/ic_debian_ubuntu.png";
import linuxLogo from "../img/ic_linux.png";
import dialogImg from "../img/ic_zaperon_setup_block.png";
import { Link, useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";

const modalSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "328px",
  height: "378px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

const downloadClientLink = process.env.REACT_APP_DOWNLOAD_URL
const DownloadPage = () => {
  const navigate = useNavigate();
  const [os, setOs] = useState("Windows");
  const [extension, setExtension] = useState(".exe");

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.includes("win")) {
      setOs("Windows");
      setExtension(".exe");
    } else if (userAgent.includes("mac")) {
      setOs("Mac");
      setExtension(".dmg");
    } else if (userAgent.includes("linux")) {
      setOs("Linux");
      setExtension(".rpm");
    } else {
      setOs("Unknown");
      setExtension(".deb");
    }
  }, []);

  const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return decodeURIComponent(cookie.substring(name.length + 1));
      }
    }
    return null;
  };

  useEffect(() => {
    const verified = getCookie('verified');
    if (verified !== 'true') {
      navigate('/linkExpired')
    }
    // eslint-disable-next-line
  }, [])

  if (isMobile || isTablet) {
    return (
      <div>
        <Modal
          keepMounted
          open={true}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={modalSx}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
                marginBottom: "16px",
              }}
            >
              <img src={dialogImg} alt="dialog" />
            </div>
            <div
              style={{
                font: "normal normal 600 16px/22px Poppins",
                width: "280px",
                color: "#0B3558",
                textAlign: "center",
              }}
            >
              Please use your corporate Laptop or PC to setup Zaperon.
            </div>
          </Box>
        </Modal>
      </div>
    );
  }

  return (
    <div>
      {/* <Header /> */}
      <div
        style={{ marginTop: "0px", marginLeft: "auto", marginRight: "auto" }}
      >
        <div
          style={{
            height: "100%",
            width: "auto",
            marginTop: "3%",
            backgroundColor: "#EEF3FF",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginBottom: "90px",
              marginTop: "120px",
              // background:"red",
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
            }}
          >
            <Box>
              <Typography
                sx={{
                  font: {
                    lg: "normal normal bold 48px/64px Poppins",
                    md: "normal normal bold 48px/64px Poppins",
                    sm: "normal normal bold 24px/64px Poppins",
                    xs: "normal normal bold 24px/64px Poppins",
                  },
                  color: "#003FB9",
                  width: "500px",
                }}
              >
                Zaperon Installation Guide!
              </Typography>
              <Box
                sx={{
                  color: "#0B3558",
                  marginTop: "48px",
                  display: {
                    lg: "block",
                    md: "block",
                    sm: "none",
                    xs: "none",
                  },
                }}
              >
                <Box display={"flex"}>
                  <div
                    style={{
                      color: "#0B3558",
                      height: "48px",
                      width: "48px",
                      border: "4px solid #35A3DA",
                      borderRadius: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      font: "normal normal bold 22px/27px Poppins",
                    }}
                  >
                    1
                  </div>
                  <div
                    style={{
                      font: "normal normal normal 18px/27px Poppins",
                      width: "536px",
                      paddingLeft: "24px",
                    }}
                  >
                    <span
                      style={{ font: " normal normal bold 18px/27px Poppins" }}
                    >
                      Download Zaperon Desktop App
                    </span>
                    , which will register your device
                  </div>
                </Box>
                <span
                  style={{
                    border: "4px solid #CEDCFF",
                    height: "24px",
                    position: "relative",
                    top: "5px",
                    left: "20px",
                  }}
                ></span>
                <Box display={"flex"} marginTop={"15px"}>
                  <div
                    style={{
                      color: "#0B3558",
                      height: "48px",
                      width: "48px",
                      border: "4px solid #35A3DA",
                      borderRadius: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      font: "normal normal bold 22px/27px Poppins",
                    }}
                  >
                    2
                  </div>
                  <div
                    style={{
                      font: "normal normal normal 18px/27px Poppins",
                      width: "536px",
                      paddingLeft: "24px",
                    }}
                  >
                    Click on the{" "}
                    <span
                      style={{ font: " normal normal bold 18px/27px Poppins" }}
                    >
                      Zaperon{extension}
                    </span>{" "}
                    from the downloaded file and install the desktop app
                  </div>
                </Box>
                <span
                  style={{
                    border: "4px solid #CEDCFF",
                    height: "24px",
                    position: "relative",
                    top: "5px",
                    left: "20px",
                  }}
                ></span>
                <Box display={"flex"} marginTop={"15px"}>
                  <div
                    style={{
                      color: "#0B3558",
                      height: "48px",
                      width: "48px",
                      border: "4px solid #35A3DA",
                      borderRadius: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      font: "normal normal bold 22px/27px Poppins",
                    }}
                  >
                    3
                  </div>
                  <div
                    style={{
                      font: "normal normal normal 18px/27px Poppins",
                      width: "536px",
                      paddingLeft: "24px",
                    }}
                  >
                    Run the desktop app by clicking on the Zaperon shortcut icon
                    created on desktop after installation
                  </div>
                </Box>
                <span
                  style={{
                    border: "4px solid #CEDCFF",
                    height: "24px",
                    position: "relative",
                    top: "5px",
                    left: "20px",
                  }}
                ></span>
                <Box display={"flex"} marginTop={"15px"}>
                  <div
                    style={{
                      color: "#0B3558",
                      height: "48px",
                      width: "48px",
                      border: "4px solid #35A3DA",
                      borderRadius: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      font: "normal normal bold 22px/27px Poppins",
                    }}
                  >
                    4
                  </div>
                  <div
                    style={{
                      font: "normal normal normal 18px/27px Poppins",
                      width: "536px",
                      paddingLeft: "24px",
                      paddingTop: "10px",
                    }}
                  >
                    Enter your business email and follow the instructions
                  </div>
                </Box>
              </Box>
            </Box>

            <Box style={{ marginLeft: "80px", marginTop: "40px" }}>
              <div
                style={{
                  width: "452px",
                  // height: "484px",
                  backgroundColor: "#FFFFFF",
                  marginRight: "48px",
                  borderRadius: "16px",
                }}
              >
                <div
                  style={{
                    paddingTop: "16px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src={AppVerifyLogo} alt="app logo" />
                </div>
                <p
                  style={{
                    font: "normal normal bold 20px/30px Poppins",
                    color: "#0B3558",
                    textAlign: "center",
                    marginTop: "16px",
                  }}
                >
                  Zaperon Client App
                </p>
                <a
                  href={downloadClientLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    height: "74px",
                    width: "388px",
                    backgroundColor: "#003FB9",
                    borderRadius: "4px",
                    margin: "auto",
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      borderRight: "0.1px solid #4075DC",
                      paddingRight: "12px",
                      marginLeft: "24px",
                    }}
                  >
                    <img
                      src={
                        os === "Windows"
                          ? winLogo
                          : os === "Mac"
                            ? appleLogo
                            : os === "Linux"
                              ? linuxLogo
                              : debLogo
                      }
                      alt="Windows Logo"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginLeft: "12px",
                    }}
                  >
                    <p
                      style={{
                        font: "normal normal normal 12px/18px Poppins",
                        color: "#FFFFFF",
                        position: "relative",
                        top: "2px",
                        textShadow: "0px 3px 6px #003FB929",
                      }}
                    >
                      DOWNLOAD
                    </p>
                    <p
                      style={{
                        font: "normal normal 600 24px/35px Poppins",
                        color: "#FFFFFF",
                      }}
                    >
                      Client Connector App
                    </p>
                  </div>
                </a>
                {os === "Windows" && (
                  <p
                    style={{
                      width: "182px",
                      font: "normal normal normal 16px/25px Poppins",
                      color: "#0B3558",
                      textAlign: "center",
                      margin: "auto",
                      marginTop: "16px",
                    }}
                  >
                    Supports Windows 10+ [X64/X86]
                  </p>
                )}
                {os === "Linux" && (
                  <p
                    style={{
                      width: "182px",
                      font: "normal normal normal 16px/25px Poppins",
                      color: "#0B3558",
                      textAlign: "center",
                      margin: "auto",
                      marginTop: "16px",
                    }}
                  >
                    Linux, RedHat, Fedora, SUSE [X64/Arm32/Arm64]
                  </p>
                )}
                {os === "Mac" && (
                  <p
                    style={{
                      width: "182px",
                      font: "normal normal normal 16px/25px Poppins",
                      color: "#0B3558",
                      textAlign: "center",
                      margin: "auto",
                      marginTop: "16px",
                    }}
                  >
                    Supports macOS 10.11+ [Intel Chip/Apple Silicon/Universal]
                  </p>
                )}
                {os === "Unknown" && (
                  <p
                    style={{
                      width: "182px",
                      font: "normal normal normal 16px/25px Poppins",
                      color: "#0B3558",
                      textAlign: "center",
                      margin: "auto",
                      marginTop: "16px",
                    }}
                  >
                    Debian, ubuntu [X64/Arm32/Arm64]
                  </p>
                )}
                <div
                  style={{
                    font: "normal normal normal 14px/21px Poppins",
                    color: "#A2A2A2",
                    textAlign: "center",
                    marginTop: "24px",
                    width: "290px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingBottom: "15px",
                  }}
                >
                  By downloading and using Zaperon Apps, you agree to the{" "}
                  <Link
                    to={"/terms-conditions"}
                    style={{
                      font: "normal normal 600 14px/21px Poppins",
                      color: "#003FB9",
                    }}
                  >
                    Terms{" "}
                  </Link>
                  &{" "}
                  <Link
                    to={"/privacyPolicy"}
                    style={{
                      font: "normal normal 600 14px/21px Poppins",
                      color: "#003FB9",
                    }}
                  >
                    Privacy Policy.
                  </Link>
                </div>
              </div>
            </Box>
          </Box>
        </div>
      </div>
      <div style={{ marginTop: "64px" }}>
        <Footer />
      </div>
    </div>
  );
};

export default DownloadPage;
